import React, { useEffect, useRef } from 'react'
import styles from './home.module.scss'
import { useDeviceStore } from '../../stateManager/DeviceStore'
import { DEVICE, PLATFORM, THEME } from '../../_shared/constants/constants'
import Camera_header from '../../assets/webp/Camera_header.webp'
import Camera_header_Mobile from '../../assets/webp/camera_header_mobile.webp'
import Iphone_desktop from '../../assets/webp/iphone_camera_desktop.webp'
import Iphone_Mobile from '../../assets/webp/iphone_camera_mobile.webp'
import QR_code from '../../assets/webp/Camera_QR_Code.webp'
import Camera_Illustration1_1 from '../../assets/webp/camera_illustration1.1.webp'
import Camera_Illustration1_2 from '../../assets/webp/camera_illustration1.2.webp'
import Camera_Illustration1_3 from '../../assets/webp/camera_illustration1.3.webp'
import Camera_Illustration2 from '../../assets/webp/camera_illustration2.webp'
import Camera_Illustration2_mobile from '../../assets/webp/camera_illustration2_mobile.webp'
import Camera_Illustration3 from '../../assets/webp/camera_illustration3.webp'
import SetsApart_desktop from '../../assets/webp/setsApart_desktop.webp'
import SetsApart_mobile from '../../assets/webp/setsApart_mobile.webp'
import Iphone_footer from '../../assets/webp/iphone_footer_desktop.webp'
import InvisiblePhotos from '../../assets/webp/invisible_photos_mobile.webp'

import DesktopPoster from '../../assets/webp/cameraDesktopVideoPoster.webp'
import MobilePoster from '../../assets/webp/cameraMobileVideoPoster.webp'

import { ReactComponent as AppStoreLogo } from '../../assets/svg/appStore_logo.svg'
import { ReactComponent as PlayStoreLogo } from '../../assets/svg/playStore_logo.svg'

import { ReactComponent as CameraFeature1 } from '../../assets/svg/camera_feature1.svg'
import { ReactComponent as CameraFeature2 } from '../../assets/svg/camera_feature2.svg'
import { ReactComponent as CameraFeature3 } from '../../assets/svg/camera_feature3.svg'
import { ReactComponent as CameraFeature4 } from '../../assets/svg/camera_feature4.svg'
import { ReactComponent as CameraFeature5 } from '../../assets/svg/camera_feature5.svg'
import { ReactComponent as CameraFeature6 } from '../../assets/svg/camera_feature6.svg'
import { ReactComponent as CameraFeature7 } from '../../assets/svg/camera_feature7.svg'
import { ReactComponent as CameraFeature8 } from '../../assets/svg/camera_feature8.svg'
import { ReactComponent as CameraFeature9 } from '../../assets/svg/camera_feature9.svg'
import { ReactComponent as CameraFeature10 } from '../../assets/svg/camera_feature10.svg'

import { Link } from 'react-router-dom'
import { useDownloadHook } from '../../_shared/hooks/download.hook'
import { useOsStore } from '../../stateManager/OsStore'

import { lazily } from 'react-lazily';
const { FloaterDownload } = lazily(() => import('../../_shared/components/FloaterDownload/floaterDownload.component'));
const { PromotionalBanner } = lazily(() => import('../../_shared/components/PromotionalBanner/promotionalBanner.component'));

function Home() {
    const { device } = useDeviceStore();
    const {
        handleBuildDownload,
    } = useDownloadHook();
    const { os } = useOsStore();
    const videoRef = useRef<HTMLVideoElement | null>(null);

    useEffect(() => {
        if (videoRef.current) videoRef.current.playbackRate = .8;
    }, [])



    return (
        <div className={styles.homeContainer}>
            <FloaterDownload title="To protect your media" buttonText="Download" mode={`${THEME.LIGHT}`} />
            <div className={styles.headerContainer}>
                <div className={styles.header}>
                    <div className={styles.heading}>
                        Keep your private life <span>private.</span>
                    </div>
                    <div className={styles.subHeading}>
                        Secure Shots, Sealed Privacy. Capture Memories Carefree. They are never revealed to anyone else.
                    </div>

                </div>
                <div className={styles.headerImg}>
                    {/* <img src={device == DEVICE.MOBILE ? `${Camera_header_Mobile}` : `${Camera_header}`} alt='Zunu camera Header' /> */}
                    <video
                        autoPlay
                        muted
                        loop
                        playsInline
                        preload="auto"
                        className={styles.video}
                        poster={device === DEVICE.MOBILE ? MobilePoster : DesktopPoster}
                        ref={videoRef}
                    >
                        <source src={`assets/webm/camera_introduction_mobile.webm`} type={`video/webm`} media="(max-width:730px)" />
                        <source src={`assets/mp4/camera_introduction_mobile.mp4`} type={`video/mp4`} media="(max-width:730px)" />
                        <source src={`assets/webm/camera_introduction_desktop.webm`} type={`video/webm`} />
                        <source src={`assets/mp4/camera_introduction_desktop.mp4`} type={`video/mp4`} />
                        Your browser does not support the video tag.
                    </video>

                </div>
            </div>

            <PromotionalBanner />

            <div className={styles.cameraContentContainer}>
                <div className={styles.downloadContainer}>
                    <div className={styles.downloadChildContainer}>
                        <div className={styles.downloadLeftContainer}>
                            <p className={styles.downloadHeader}>Protect Your Memories with Zunu Camera's Unmatched Privacy.</p>

                            <div className={styles.download_Os_container}>
                                {device !== DEVICE.MOBILE &&
                                    <div className={styles.downloadBtnContainer}>
                                        <button className={styles.downloadBtn} onClick={() => handleBuildDownload(os)} id="banner1Download">Download Now</button>


                                        <div className={styles.osLogoContainer}>
                                            <Link to="" onClick={() => handleBuildDownload(PLATFORM.IOS)} id="banner1AppStore"><AppStoreLogo className={styles.osLogos} /></Link>
                                            <Link to="" onClick={() => handleBuildDownload(PLATFORM.AND)} id="banner1PlayStore"><PlayStoreLogo className={styles.osLogos} /></Link>
                                        </div>

                                    </div>
                                }

                            </div>

                        </div>
                        <div className={styles.downloadImgContainer}>
                            <img src={device == DEVICE.MOBILE ? `${Iphone_Mobile}` : `${Iphone_desktop}`} />
                        </div>


                        {device == DEVICE.MOBILE &&
                            <div className={styles.osLogoContainer}>
                                <Link to="" onClick={() => handleBuildDownload(PLATFORM.IOS)} id="banner1AppStore"><AppStoreLogo className={styles.osLogos} /></Link>
                                <Link to="" onClick={() => handleBuildDownload(PLATFORM.AND)} id="banner1PlayStore"><PlayStoreLogo className={styles.osLogos} /></Link>
                            </div>
                        }
                    </div>
                </div>

            </div>

            <div className={`${styles.cameraFeatures_Container}  ${styles.cameraContentContainer}`}>
                <div className={styles.cameraFeatures_header}>
                    Capture Moments, Keep Privacy with Zunu Camera.
                </div>
                <div className={styles.features_content}>
                    <div className={styles.camera_feature}>
                        <CameraFeature1 className={styles.featureIcons} />
                        <div className={styles.descriptionTextContainer}>
                            <p className={styles.featureDescription}>
                                Encrypted Capture
                            </p>
                            <p className={styles.featureDescriptionText}>Capture with Confidence. Media Stays Completely Private all the time.</p>
                        </div>
                    </div>
                    <div className={styles.camera_feature}>
                        <CameraFeature5 className={styles.featureIcons} />
                        <div className={styles.descriptionTextContainer}>
                            <p className={styles.featureDescription}>
                                Secure from Attacks
                            </p>
                            <p className={styles.featureDescriptionText}>Media is Safe from Ransomware, Malware, and Viruses.</p>
                        </div>
                    </div>
                    <div className={styles.camera_feature}>
                        <CameraFeature2 className={styles.featureIcons} />
                        <div className={styles.descriptionTextContainer}>
                            <p className={styles.featureDescription}>
                                Hidden from Apps
                            </p>
                            <p className={styles.featureDescriptionText}>Media Captured is Invisible to 3rd Party apps with Gallery Access permissions.</p>
                        </div>
                    </div>
                    <div className={styles.camera_feature}>
                        <CameraFeature6 className={styles.featureIcons} />
                        <div className={styles.descriptionTextContainer}>
                            <p className={styles.featureDescription}>
                                Breach Protection
                            </p>
                            <p className={styles.featureDescriptionText}>Media Remains Secure Even if Phone Is Compromised.</p>
                        </div>
                    </div>
                    <div className={styles.camera_feature}>
                        <CameraFeature7 className={styles.featureIcons} />
                        <div className={styles.descriptionTextContainer}>
                            <p className={styles.featureDescription}>
                                Encrypted Meta Data
                            </p>
                            <p className={styles.featureDescriptionText}>All media metadata, including date, timestamp, location, etc., is encrypted to ensure privacy.</p>
                        </div>
                    </div>
                    <div className={styles.camera_feature}>
                        <CameraFeature4 className={styles.featureIcons} />
                        <div className={styles.descriptionTextContainer}>
                            <p className={styles.featureDescription}>
                                Dark Web Safe
                            </p>
                            <p className={styles.featureDescriptionText}>Media is Protected from Harmful AI and Dark Web.</p>
                        </div>
                    </div>
                    <div className={styles.camera_feature}>
                        <CameraFeature8 className={styles.featureIcons} />
                        <div className={styles.descriptionTextContainer}>
                            <p className={styles.featureDescription}>
                                Support for Multiple Cloud Accounts
                            </p>
                            <p className={styles.featureDescriptionText}>Add unlimited cloud accounts and seamlessly sync media with any of them.</p>
                        </div>
                    </div>
                    <div className={styles.camera_feature}>
                        <CameraFeature9 className={styles.featureIcons} />
                        <div className={styles.descriptionTextContainer}>
                            <p className={styles.featureDescription}>
                                Encrypted Storage
                            </p>
                            <p className={styles.featureDescriptionText}>The captured media is stored encrypted.</p>
                        </div>
                    </div>
                    <div className={styles.camera_feature}>
                        <CameraFeature3 className={styles.featureIcons} />
                        <div className={styles.descriptionTextContainer}>
                            <p className={styles.featureDescription}>
                                Tamper-Proof
                            </p>
                            <p className={styles.featureDescriptionText}>Photos and Videos Cannot Be Altered or Morphed.</p>
                        </div>
                    </div>
                    <div className={styles.camera_feature}>
                        <CameraFeature10 className={styles.featureIcons} />
                        <div className={styles.descriptionTextContainer}>
                            <p className={styles.featureDescription}>
                                Compatible with Google Drive and OneDrive
                            </p>
                            <p className={styles.featureDescriptionText}>Media can be synced encrypted with Google Drive and OneDrive.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.simplifiedPrivacyContainer} ${styles.cameraContentContainer}`}>
                <div className={styles.simplifiedPrivacyChild}>
                    <div className={styles.simplifiedHeader}>
                        <div className={styles.cameraFeatures_header}>
                            Invisible Photos & Videos
                        </div>
                    </div>
                    {device !== DEVICE.MOBILE ?
                        <div className={styles.privacyContainer}>
                            <div className={styles.privacyContent}>
                                <img src={Camera_Illustration1_1} alt='camera' />
                                <p className={styles.privacyContentText}>Capture Moments Effortlessly</p>
                            </div>
                            <div className={styles.privacyContent}>
                                <img src={Camera_Illustration1_2} alt='image storage' />
                                <p className={styles.privacyContentText}>Media Stored in the Zunu Ecosystem</p>
                            </div>

                            <div className={styles.privacyContent}>
                                <img src={Camera_Illustration1_3} alt='invisible gallery' />
                                <p className={styles.privacyContentText}>Invisible in Phone's Gallery</p>
                            </div>
                        </div>
                        :
                        <div className={styles.privacyContainers}>
                            <img src={InvisiblePhotos} />
                        </div>
                    }
                </div>

            </div>

            <div className={`${styles.cameraPrivacyContainer} ${styles.cameraContentContainer}`}>
                <div className={styles.cameraFeatures_header}>
                    Media is Private on Device.
                </div>
                <div className={styles.privateMediaImg}>
                    <p className={styles.privateMediaText}>3rd party apps with Gallery access permissions can't see Media- No more data sharing, Targeted Ads or Misuse.</p>
                    <img src={device == DEVICE.MOBILE ? `${Camera_Illustration2_mobile}` : `${Camera_Illustration2}`} alt='illustration' />
                </div>
            </div>

            <div className={`${styles.illustrationContainer} ${styles.cameraContentContainer}`}>
                <div className={styles.cameraFeatures_header}>
                    Securely Shielded
                </div>
                <div className={styles.illustrationTextImg}>
                    <div className={styles.illustrationText}>
                        <p className={styles.illustrationSubHeader}>Photos <span>and</span> Videos <span>are immune to</span> Ransomware<span>,</span> Malware<span>, and</span>  Viruses.</p>
                    </div>
                    <div className={styles.securelyShieldedImg}>
                        <img src={Camera_Illustration3} alt='mail illustration' />
                    </div>
                </div>
            </div>

            <div className={`${styles.setUsApartContainer} ${styles.cameraContentContainer}`}>
                <div className={styles.setApartHeader}>
                    <div className={styles.cameraFeatures_header}>
                        What sets us Apart?
                    </div>
                    <div className={styles.setApartText}>Our ultimate robust Device-to-Device End-to-End Encryption for 100% Privacy</div>

                </div>
                <div className={styles.setApartImg}>
                    <img src={device == DEVICE.MOBILE ? `${SetsApart_mobile}` : `${SetsApart_desktop}`} alt='illustration' />
                </div>
            </div>

            <div className={styles.footerContainer}>
                <div className={styles.footerLeftContainer}>
                    <p className={styles.footerText_header}>Protect Your Memories with Zunu Camera's Unmatched Privacy.</p>
                    <div className={styles.download_Qr_scanner}>
                        {device !== DEVICE.MOBILE &&
                            <div className={styles.footerDownload_container}>

                                <div className={styles.footerBtn_container}>
                                    <button className={styles.downloadBtn} id="banner2Download">Download Now</button>
                                </div>

                                <div className={styles.footerLogoContainer}>
                                    <Link to="" onClick={() => handleBuildDownload(PLATFORM.IOS)} id="banner2AppStore"><AppStoreLogo className={styles.osLogos} /></Link>
                                    <Link to="" onClick={() => handleBuildDownload(PLATFORM.AND)} id="banner2PlayStore"><PlayStoreLogo className={styles.osLogos} /></Link>
                                </div>

                            </div>
                        }
                    </div>
                </div>
                <div className={styles.footerImgContainer}>
                    <div className={styles.footerImg}>
                        <img src={device == DEVICE.MOBILE ? `${Iphone_footer}` : `${Iphone_footer}`} alt='pixel mobile' />
                    </div>

                    {device == DEVICE.MOBILE &&
                        <div className={styles.footerLogoContainer}>
                            <Link to="" onClick={() => handleBuildDownload(PLATFORM.IOS)} id="banner2AppStore"><AppStoreLogo className={styles.osLogos} /></Link>
                            <Link to="" onClick={() => handleBuildDownload(PLATFORM.AND)} id="banner2PlayStore"><PlayStoreLogo className={styles.osLogos} /></Link>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export { Home }